import axios from "axios";

const client = axios.create({
  baseURL: "https://cognitoauth.wangsiha.onboard.skillbuilder.training.aws.dev",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "text/plain",
    "Referrer-Policy": "unsafe-url"
  }
});

export default client;