import React, {useEffect} from 'react';
import client from './client';
  
const CheckAuth = () => {
  const [url, setRedirectUrl] = React.useState(null);
  // useEffect(() => {
  //   client.get('/checkauth').then((response) => {
  //      console.log("checkauth response is", response);
  //     //  setRedirectUrl(response.headers.location);
  //   });
  // });

  return (
    <div>
      <h1>
       Check Auth! Sign in redirectly if not already.
       <div id='button'>
        <button>Check Auth</button>
       </div>
      </h1>
    </div>
  );
};
  
export default CheckAuth;