
import React from 'react';
  
const Home = () => {
  const currentUrl = window.location.href;
  const state = currentUrl.split("?")[1];
  const checkauthUrl = "https://cognitoauth.wangsiha.onboard.skillbuilder.training.aws.dev/checkauth"+"?"+state+"&redirect_uri=https://testapp1.wangsiha.onboard.skillbuilder.training.aws.dev".toString();
  
  return (
    <div>
      <h1>Welcome! This is test app 1 from testapp1.wangsiha.xxx domain!</h1>
      <div id='button'>
        {/* <button onClick={checkauth}>Check Auth</button> */}
        <a href="https://cognitoauth.wangsiha.onboard.skillbuilder.training.aws.dev/checkauth?redirect_uri=https://testapp1.wangsiha.onboard.skillbuilder.training.aws.dev">Check Auth</a>

        <a href="https://cognitoauth.wangsiha.onboard.skillbuilder.training.aws.dev/refresh?redirect_uri=https://testapp1.wangsiha.onboard.skillbuilder.training.aws.dev">Refresh token</a>

        <a href="https://cognitoauth.wangsiha.onboard.skillbuilder.training.aws.dev/signout?redirect_uri=https://testapp1.wangsiha.onboard.skillbuilder.training.aws.dev">Sign out</a>
      </div>
    </div>
  );
};
  
export default Home;